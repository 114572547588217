// src/components/CreditScoreBox.tsx
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Line } from 'react-chartjs-2';
import styles from './CreditScoreBox.module.css';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const GET_CREDIT_SCORE_DATA = gql`
  query MyQuery($userId: String!, $refreshToken: String!) {
    getNubiUser(user_id: $userId, refresh_token: $refreshToken) {
      user_data {
        credit_score_data {
          amortization
          cost_goods_services_sold
          current_assets
          current_liabilities
          depreciation
          long_term_debt
          net_income
          operating_expenses
          pretax_income
          revenue
          credit_score_history {
            credit_score
            timestamp
          }
        }
      }
    }
  }
`;

interface CreditScoreBoxProps {
  userId: string;
  refreshToken: string;
  initialCreditScore: number;
  initialTimestamp: string;
}

const CreditScoreBox: React.FC<CreditScoreBoxProps> = ({ userId, refreshToken, initialCreditScore, initialTimestamp }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { loading, error, data } = useQuery(GET_CREDIT_SCORE_DATA, {
    variables: { userId, refreshToken },
    skip: !isExpanded, // Only run the query when the box is expanded
  });

  const creditScorePercentage = (initialCreditScore / 1000) * 100;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderFinancialData = () => {
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const financialData = data?.getNubiUser?.user_data?.credit_score_data;
    if (!financialData) return <p>No financial data available</p>;

    return (
      <ul className={styles.financialList}>
        <li>Amortization: ${financialData.amortization}</li>
        <li>Cost of Goods/Services Sold: ${financialData.cost_goods_services_sold}</li>
        <li>Current Assets: ${financialData.current_assets}</li>
        <li>Current Liabilities: ${financialData.current_liabilities}</li>
        <li>Depreciation: ${financialData.depreciation}</li>
        <li>Long Term Debt: ${financialData.long_term_debt}</li>
        <li>Net Income: ${financialData.net_income}</li>
        <li>Operating Expenses: ${financialData.operating_expenses}</li>
        <li>Pretax Income: ${financialData.pretax_income}</li>
        <li>Revenue: ${financialData.revenue}</li>
      </ul>
    );
  };

  const renderCreditScoreHistory = () => {
    if (!data?.getNubiUser?.user_data?.credit_score_data?.credit_score_history) return null;

    const history = data.getNubiUser.user_data.credit_score_data.credit_score_history;
    const chartData = {
      labels: history.map((item: any) => new Date(item.timestamp).toLocaleDateString()),
      datasets: [
        {
          label: 'Credit Score',
          data: history.map((item: any) => item.credit_score),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                max: 1000
            }
        }
    };

    return (
      <div className={styles.creditScoreHistory}>
        <h3>Credit Score History</h3>
        <Line data={chartData} options={options}/>
      </div>
    );
  };

  return (
    <div 
        className={`${styles.creditScoreBox} ${isExpanded ? styles.expanded : ''}`}
    >
      <h2>Credit Score</h2>
      <div className={styles.creditScoreWheel}>
        <svg viewBox="0 0 36 36" className={styles.circularChart}>
          <path 
            className={styles.circleBg}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path 
            className={styles.circle}
            strokeDasharray={`${creditScorePercentage}, 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" className={styles.percentage}>{initialCreditScore}</text>
        </svg>
      </div>
      <p className={styles.lastUpdated}>Last updated: {initialTimestamp}</p>
      <button onClick={toggleExpand} className={styles.toggleButton}>
        {isExpanded ? 'Hide Analysis' : 'View Score Breakdown'}
      </button>
      {isExpanded && (
        <div className={styles.expandedContent}>
          {/* <h3>Financial Data</h3> */}
          {/* {renderFinancialData()} */}
          {renderCreditScoreHistory()}
        </div>
      )}
    </div>
  );
};

export default CreditScoreBox;