import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import './ArtifactsContainer.css';

interface DocumentVersion {
  htmlContent: string;
  links: { format: string; url: string }[];
}

interface ActionItem {
  id: string;
  title: string;
  description: string;
  saved: boolean;
}

interface ArtifactsContainerProps {
  userId: string;
  documents: {
    [key: string]: DocumentVersion[];
  };
  actionItems: ActionItem[];
  onUpdateActionItems: (updatedItems: ActionItem[]) => void;
  activeTab: string;
}

const GET_ACTION_ITEMS = gql`
  query MyQuery($userId: String!) {
    getActionItems(user_id: $userId) {
      title
      description
    }
  }
`;

const UPDATE_ACTION_ITEMS = gql`
  mutation MyMutation($userId: String!, $actionItems: [ActionItemInput!]) {
    updateActionItems(user_id: $userId, action_items: $actionItems) {
      status_code
      error_message
    }
  }
`;

const ArtifactsContainer: React.FC<ArtifactsContainerProps> = ({ userId, documents, actionItems, onUpdateActionItems, activeTab }) => {
  const [currentVersionIndex, setCurrentVersionIndex] = useState<{ [key: string]: number }>({
    "Business Plan": 0,
    "Invoice": 0,
    "Credit Score Report": 0,
  });
  const [oldActionItems, setOldActionItems] = useState<ActionItem[]>([]);

  const { loading, error, data } = useQuery(GET_ACTION_ITEMS, {
    variables: { userId },
  });

  const [updateActionItems] = useMutation(UPDATE_ACTION_ITEMS);

  useEffect(() => {
    if (data && data.getActionItems) {
      setOldActionItems(data.getActionItems.map((item: any, index: number) => ({
        id: `old-${index}`,
        ...item,
        saved: true,
      })));
    }
  }, [data]);

  const handleVersionChange = (direction: 'prev' | 'next') => {
    setCurrentVersionIndex(prev => ({
      ...prev,
      [activeTab]: direction === 'prev'
        ? Math.max(0, prev[activeTab] - 1)
        : Math.min((documents[activeTab]?.length || 1) - 1, prev[activeTab] + 1),
    }));
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentDocument = documents[activeTab]?.[currentVersionIndex[activeTab]];

  const handleRemoveActionItem = (id: string, isOld: boolean) => {
    if (isOld) {
      setOldActionItems(prevItems => prevItems.filter(item => item.id !== id));
    } else {
      const updatedItems = actionItems.filter(item => item.id !== id);
      onUpdateActionItems(updatedItems);
    }
  };

  const handleSaveAllActionItems = async () => {
    const allItems = [...actionItems, ...oldActionItems];
    const actionItemsInput = allItems.map(({ title, description }) => ({ title, description }));
    
    try {
      const result = await updateActionItems({
        variables: {
          userId,
          actionItems: actionItemsInput,
        },
      });
      
      if (result.data.updateActionItems.status_code === 200) {
        alert("Action items saved successfully");
        const updatedNewItems = actionItems.map(item => ({ ...item, saved: true }));
        onUpdateActionItems(updatedNewItems);
      } else {
        alert("Error saving action items.")
        console.error("Error saving action items:", result.data.updateActionItems.error_message);
      }
    } catch (error) {
      alert("Error executing mutation.")
      console.error("Error executing mutation:", error);
    }
  };

  const renderDocumentContent = () => (
    <>
      <iframe 
        srcDoc={currentDocument?.htmlContent || ''}
        title={activeTab}
        className="document-iframe"
      />
      <div className="version-control">
        <button onClick={() => handleVersionChange('prev')}>&lt;</button>
        <span>Version {currentVersionIndex[activeTab] + 1} of {documents[activeTab]?.length || 0}</span>
        <button onClick={() => handleVersionChange('next')}>&gt;</button>
        <div className="download-container">
          <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
            {currentDocument?.links.map((link, index) => (
              <a key={index} href={link.url} download>{link.format}</a>
            ))}
          </div>
          <button 
            className="download-button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            Download
          </button>
        </div>
      </div>
    </>
  );

  const renderActionItems = () => (
    <div className="action-items-wrapper">
      <div className="action-items-scroll">
        <div className="action-items-section">
          <h2>Action Items</h2>
          {actionItems.length === 0 ? (
            <p>Action items recommended by the chat will appear here.</p>
          ) : (
            actionItems.map((item) => (
              <div key={item.id} className={`action-item ${item.saved ? 'saved' : ''}`}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <button 
                  className="action-item-button"
                  onClick={() => handleRemoveActionItem(item.id, false)}
                >
                  Remove Action Item
                </button>
              </div>
            ))
          )}
        </div>
        <div className="action-items-section">
          <h2>Old Action Items</h2>
          {loading ? (
            <p>Loading old action items...</p>
          ) : error ? (
            <p>Error loading old action items</p>
          ) : (
            oldActionItems.map((item) => (
              <div key={item.id} className="action-item saved">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <button 
                  className="action-item-button"
                  onClick={() => handleRemoveActionItem(item.id, true)}
                >
                  Remove Action Item
                </button>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="save-action-items-bar">
        <button 
          className="save-action-items-button"
          onClick={handleSaveAllActionItems}
        >
          Save Action Items
        </button>
      </div>
    </div>
  );

  return (
    <div className="artifacts-container">
      {activeTab === "Action Items" ? renderActionItems() : renderDocumentContent()}
    </div>
  );
};

export default ArtifactsContainer;