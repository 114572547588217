import React, { useState, useCallback } from 'react';
import PasswordChecklist from "react-password-checklist";
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface PasswordComponentProps {
    password: string;
    onPasswordChange: (password: string) => void;
    onValidityChange: (isValid: boolean) => void;
}

const PasswordInput = React.memo(({ 
  type, 
  name, 
  value, 
  onChange, 
  placeholder, 
  showPassword, 
  onToggle 
}: {
  type: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  showPassword: boolean;
  onToggle: () => void;
}) => (
  <div style={{ position: 'relative', display: 'block', width: '100%' }}>
    <input
      type={showPassword ? "text" : "password"}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required
      style={{ paddingRight: '30px', width: '100%', boxSizing: 'border-box' }}
    />
    <span 
      onClick={onToggle}
      style={{
        position: 'absolute',
        right: '5px',
        top: '45%',
        transform: 'translateY(-45%)',
        cursor: 'pointer',
        color: '#757575',
        backgroundColor: 'transparent'
      }}
    >
      {showPassword ? <VisibilityOff /> : <Visibility />}
    </span>
  </div>
));

const PasswordComponent: React.FC<PasswordComponentProps> = ({
  password,
  onPasswordChange,
  onValidityChange
}) => {
    
  const [passwordAgain, setPasswordAgain] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);

  const togglePasswordVisibility = useCallback((field: 'password' | 'passwordAgain') => {
    if (field === 'password') {
      setShowPassword(prev => !prev);
    } else {
      setShowPasswordAgain(prev => !prev);
    }
  }, []);

  const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onPasswordChange(e.target.value);
  }, [onPasswordChange]);

  const handlePasswordAgainChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordAgain(e.target.value);
  }, []);

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <div style={{ flex: '1', maxWidth: '50%' }}>
      <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', fontSize: "11px" }}>
          <i>Please follow the password requirements.</i>
        </label>
        <PasswordInput
          type="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          showPassword={showPassword}
          onToggle={() => togglePasswordVisibility('password')}
        />
        <PasswordInput
          type="password"
          name="passwordAgain"
          value={passwordAgain}
          onChange={handlePasswordAgainChange}
          placeholder="Confirm password"
          showPassword={showPasswordAgain}
          onToggle={() => togglePasswordVisibility('passwordAgain')}
        />
      </div>
      <div style={{ flex: '1', maxWidth: '50%' }}>
        <PasswordChecklist
          rules={["minLength","specialChar","number","capital","lowercase","match"]}
          minLength={8}
          value={password}
          valueAgain={passwordAgain}
          onChange={onValidityChange}
          iconSize={12}
          style={{
            fontSize: "12px"
          }}
        />
      </div>
    </div>
  );
};

export default PasswordComponent;