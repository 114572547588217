// src/App.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import ddb_client from './DDB_Client';
import llm_backend_client from './LLM-Backend-Client';
import api_key_client from './API_Key_Client';
import SignInPage from './SignIn/SignInPage';
import CreateAccountPage from './SignIn/CreateAccountPage';
import ChooseAccountTypePage from './SignIn/ChooseAccountTypePage';
import ErrorMessageScreen from './TempScreens/ErrorMessageScreen';
import Verification from './SignIn/Verification';
import Chat from './Chat';
import Home from './Home';
import Documents from './Documents';
import Networking from './Networking';
import UserProfile from './UserProfile';
import BusinessProfile from './BusinessProfile';
import LandingPage from './LandingPage';
import { AuthProvider, useAuth } from './AuthContext';
import AccessCodePage from './AccessCodePage';

// Custom hook to simulate page reload
const useSimulatedReload = () => {
  const [reloadFlag, setReloadFlag] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setReloadFlag(prev => prev + 1);
  }, [location.pathname]);

  const simulateReload = useCallback(() => {
    setReloadFlag(prev => prev + 1);
  }, []);

  return { reloadFlag, simulateReload };
};

const AppContent: React.FC = () => {
  const { 
    userId, setUserId, 
    refreshToken, setRefreshToken, 
    isAuth, setIsAuth, 
    businessId, setBusinessId,
    error, setError,
    linkBusiness, setLinkBusiness,
    login, logout,
    startingLogin,
    signifyOnLandingPage
  } = useAuth();

  const [accessCodeVerified, setAccessCodeVerified] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { reloadFlag, simulateReload } = useSimulatedReload();

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        await login();
        if (location.pathname === '/signin') {
          navigate('/home');
        }
      } catch (error) {
        setIsAuth(false);
        if (!startingLogin) {
          signifyOnLandingPage(true);
          navigate('/landingpage');
        }
      }
    };

    const hasAccessCodeVerified = localStorage.getItem('accessCodeVerified');
    if (hasAccessCodeVerified === 'true') {
      setAccessCodeVerified(true);
    }

    checkAuthState();
  }, [login, location.pathname, reloadFlag]);

  if (error) {
    return <ErrorMessageScreen />;
  }

  if (!accessCodeVerified) {
    return <AccessCodePage onVerificationSuccess={() => {
      setAccessCodeVerified(true);
      localStorage.setItem('accessCodeVerified', 'true');
    }} />;
  }

  return (
    <Routes>
      <Route 
        path="/landingpage" 
        element={<LandingPage />} 
      />
      <Route 
        path="/signin" 
        element={
          <ApolloProvider client={ddb_client}>
            <SignInPage 
              onSignInSuccess={(uid, token) => {
                setUserId(uid);
                setRefreshToken(token);
                setIsAuth(true);
                navigate('/home');
              }}
              onError={setError}
            />
          </ApolloProvider>
        } 
      />
      <Route 
        path="/choose-account-type" 
        element={
          <ChooseAccountTypePage 
            onChoice={(choice) => {
              setLinkBusiness(choice);
              navigate('/create-account');
            }}
          />
        } 
      />
      <Route 
        path="/create-account" 
        element={
          <ApolloProvider client={api_key_client}>
            <CreateAccountPage onError={setError} linkBusiness={linkBusiness} />
          </ApolloProvider>
        } 
      />
      <Route 
        path="/verification" 
        element={
          <ApolloProvider client={api_key_client}>
            <Verification />
          </ApolloProvider>
        } 
      />
      <Route 
        path="/home" 
        element={
          isAuth ? (
            <ApolloProvider client={ddb_client}>
              <Home 
                onBusinessIdLoaded={(bid) => setBusinessId(bid)}
                key={reloadFlag}
                simulateReload={simulateReload}
              />
            </ApolloProvider>
          ) : (
            <Navigate to="/signin" replace />
          )
        } 
      />
      <Route 
        path="/chat" 
        element={
          isAuth ? (
            <ApolloProvider client={llm_backend_client}>
              <Chat 
                userId={userId} 
                refreshToken={refreshToken} 
                incognito={false} 
                key={reloadFlag}
              />
            </ApolloProvider>
          ) : (
            <Navigate to="/signin" replace />
          )
        }
      />
      <Route 
        path="/incognito_chat" 
        element={
          isAuth ? (
            <ApolloProvider client={llm_backend_client}>
              <Chat 
                userId={userId} 
                refreshToken={refreshToken} 
                incognito={true} 
                key={reloadFlag}
              />
            </ApolloProvider>
          ) : (
            <Navigate to="/signin" replace />
          )
        }
      />
      <Route 
        path="/documents" 
        element={
          isAuth ? (
            <ApolloProvider client={ddb_client}>
              <Documents 
                userId={userId} 
                key={reloadFlag}
              />
            </ApolloProvider>
          ) : (
            <Navigate to="/signin" replace />
          )
        } 
      />
      <Route 
        path="/networking" 
        element={
          isAuth ? (
            <ApolloProvider client={ddb_client}>
              <Networking 
                userId={userId} 
                businessId={businessId} 
                refreshToken={refreshToken} 
                key={reloadFlag}
                simulateReload={simulateReload}
              />
            </ApolloProvider>
          ) : (
            <Navigate to="/signin" replace />
          )
        } 
      />
      <Route 
        path="/user_profile" 
        element={
          isAuth ? (
            <ApolloProvider client={ddb_client}>
              <UserProfile 
                userId={userId} 
                refreshToken={refreshToken} 
                key={reloadFlag}
                simulateReload={simulateReload}
              />
            </ApolloProvider>
          ) : (
            <Navigate to="/signin" replace />
          )
        } 
      />
      <Route 
        path="/business_profile" 
        element={
          isAuth ? (
            <ApolloProvider client={ddb_client}>
              <BusinessProfile 
                businessId={businessId} 
                key={reloadFlag}
                simulateReload={simulateReload}
              />
            </ApolloProvider>
          ) : (
            <Navigate to="/signin" replace />
          )
        } 
      />
      <Route 
        path="/logout" 
        element={
          <LogoutRoute logout={logout} />
        } 
      />
      <Route path="*" element={<Navigate to="/landingpage" replace />} />
    </Routes>
  );
};

const LogoutRoute: React.FC<{ logout: () => void }> = ({ logout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate('/landingpage', { replace: true });
  }, [logout, navigate]);

  return null;
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;