import React, { useState } from 'react';
import styles from './ActionItems.module.css';

interface ActionItem {
  title: string;
  description: string;
}

interface ActionItemsProps {
  items: ActionItem[];
  onItemsChange: (items: ActionItem[]) => void;
  isAddingItem: boolean;
  setIsAddingItem: (isAdding: boolean) => void;
}

const ActionItems: React.FC<ActionItemsProps> = ({ items, onItemsChange, isAddingItem, setIsAddingItem }) => {
  const [newItem, setNewItem] = useState<ActionItem>({ title: '', description: '' });

  const handleRemoveItem = (index: number) => {
    const updatedItems = items.filter((_, i) => i !== index);
    onItemsChange(updatedItems);
  };

  const handleAddItem = () => {
    if (newItem.title && newItem.description) {
      const updatedItems = [...items, newItem];
      onItemsChange(updatedItems);
      setNewItem({ title: '', description: '' });
      setIsAddingItem(false);
    }
  };

  return (
    <div className={styles.actionItemsContainer}>
      <h2>My Action Items</h2>
      <div className={styles.actionItemsList}>
        {items.map((item, index) => (
          <div key={index} className={styles.actionItem}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <button
              className={styles.removeButton}
              onClick={() => handleRemoveItem(index)}
            >
              Remove Action Item
            </button>
          </div>
        ))}
      </div>
      {isAddingItem && (
        <div className={styles.addItemForm}>
          <input
            type="text"
            placeholder="Title"
            value={newItem.title}
            onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
            className={styles.inputField}
          />
          <textarea
            placeholder="Description"
            value={newItem.description}
            onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
            className={styles.inputField}
          />
          <div className={styles.formButtons}>
            <button className={styles.removeButton} onClick={handleAddItem}>Add</button>
            <button className={styles.removeButton} onClick={() => setIsAddingItem(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionItems;