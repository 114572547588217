import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import ProfileWrapper from './PageComponents/ProfileWrapper';
import CountryDropdown from './PageComponents/CountryDropdown';
import LoadingScreen from './TempScreens/LoadingScreen';
import ErrorMessageScreen from './TempScreens/ErrorMessageScreen';
import styles from './UserProfile.module.css';

const GET_USER_DATA = gql`
  query GetUserData($userId: String!, $refreshToken: String!) {
    getNubiUser(user_id: $userId, refresh_token: $refreshToken) {
      user_data {
        city
        country
        first_name
        email
        last_name
        phone
        user_profile {
          personal_goals
          user_expertise
          user_interests
        }
        photo_link
      }
    }
  }
`;

const UPDATE_USER_DATA = gql`
  mutation UpdateUserData($input: UpdateUserDataInput!) {
    updateUserData(input: $input) {
      status_code
      error_message
    }
  }
`;

interface UserProfileProps {
  userId: string;
  refreshToken: string;
  simulateReload: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({ userId, refreshToken, simulateReload }) => {
  const { loading, error, data, refetch } = useQuery(GET_USER_DATA, {
    variables: { userId, refreshToken },
  });

  const [updateUserData] = useMutation(UPDATE_USER_DATA);

  useEffect(() => {
    refetch();
  }, [refetch, simulateReload]);

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    city: '',
    country: '',
    phone: '',
    photo_link: null as File | null,
  });

  const [userProfile, setUserProfile] = useState({
    personal_goals: [] as string[],
    user_expertise: [] as string[],
    user_interests: [] as string[],
  });

  const [editingItem, setEditingItem] = useState<{category: string, index: number, value: string} | null>(null);

  console.log(data)

  useEffect(() => {
    if (data?.getNubiUser?.user_data) {
      const { first_name, last_name, city, country, phone, user_profile } = data.getNubiUser.user_data;
      setFormData({ first_name, last_name, city, country, phone, photo_link: null });
      // Ensure that each category in user_profile is an array, defaulting to an empty array if null
      setUserProfile({
        personal_goals: user_profile.personal_goals || [],
        user_expertise: user_profile.user_expertise || [],
        user_interests: user_profile.user_interests || [],
      });
    }
  }, [data]);

  if (loading) return <LoadingScreen/>;
  if (error) return <ErrorMessageScreen/>;

  const { user_data } = data.getNubiUser;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, photo_link: e.target.files[0] });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let photoLinkJson = null;
      if (formData.photo_link) {
        const base64 = await convertFileToBase64(formData.photo_link);
        photoLinkJson = JSON.stringify({
          filename: formData.photo_link.name,
          type: formData.photo_link.type,
          base64: base64
        });
      }

      const { data } = await updateUserData({
        variables: {
          input: {
            ...formData,
            photo_link: photoLinkJson,
            user_id: userId,
            email: user_data.email, // Email is required but not editable
          },
        },
      });
      if (data.updateUserData.status_code !== 200) {
        alert(data.updateUserData.error_message);
      } else {
        setIsEditing(false);
        simulateReload();
      }
    } catch (error) {
      alert('An error occurred while updating user data.');
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const handleProfileItemRemove = (category: string, index: number) => {
    setUserProfile(prev => ({
      ...prev,
      [category]: prev[category as keyof typeof prev].filter((_, i) => i !== index)
    }));
  };

  const handleProfileItemEdit = (category: string, index: number, value: string) => {
    setEditingItem({ category, index, value });
  };

  const handleProfileItemSave = () => {
    if (editingItem) {
      const { category, index, value } = editingItem;
      setUserProfile(prev => ({
        ...prev,
        [category]: prev[category as keyof typeof prev].map((item, i) => i === index ? value : item)
      }));
      setEditingItem(null);
    }
  };

  const handleProfileItemChange = (value: string) => {
    if (editingItem) {
      setEditingItem({ ...editingItem, value });
    }
  };

  const handleProfileItemAdd = (category: string) => {
    setUserProfile(prev => ({
      ...prev,
      [category]: [...prev[category as keyof typeof prev], '']
    }));
    setEditingItem({ category, index: userProfile[category as keyof typeof userProfile].length, value: '' });
  };

  const handleSaveUserProfile = async () => {
    try {
      const { data } = await updateUserData({
        variables: {
          input: {
            user_id: userId,
            user_profile: {
              personal_goals: userProfile.personal_goals,
              user_expertise: userProfile.user_expertise,
              user_interests: userProfile.user_interests
            }
          },
        },
      });
      if (data.updateUserData.status_code !== 200) {
        alert(data.updateUserData.error_message);
      } else {
        simulateReload();
        alert('User profile updated successfully');
      }
    } catch (error) {
      alert('An error occurred while updating user profile.');
    }
  };

  const categoryOrder = ['personal_goals', 'user_interests', 'user_expertise'] as const;
  
  const categoryLabels = {
    personal_goals: "This category is used to match you with potential mentors.",
    user_interests: "This category is used in tandem with your personal goals to match you with potential mentors.",
    user_expertise: "If you are a mentor, this category is used to match you with potential mentees."
  };

  const categoryDisplayNames = {
    personal_goals: "Personal Goals",
    user_interests: "Interests",
    user_expertise: "Skillset"
  };

  return (
    <ProfileWrapper
      pageTitle={`${user_data.first_name} ${user_data.last_name}`}
      subtitle={`${user_data.city}, ${user_data.country} | ${user_data.email}`}
      photoLink={user_data.photo_link}
    >
      <button onClick={() => setIsEditing(true)} className={styles.editButton}>
        Edit Personal Data
      </button>

      {isEditing && (
        <div className={styles.editPanel}>
          <form onSubmit={handleSubmit}>
            <input
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              placeholder="First Name"
            />
            <input
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              placeholder="Last Name"
            />
            <input
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
            />
            <CountryDropdown
              value={formData.country}
              onChange={handleInputChange}
            />
            <input
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone"
            />
            <input
              type="file"
              onChange={handleFileUpload}
              accept="image/*"
            />
            <button type="submit">Save Changes</button>
            <button type="button" onClick={() => setIsEditing(false)}>Cancel</button>
          </form>
        </div>
      )}

      <div className={styles.userProfileBox}>
        <h2>User Profile</h2>
        {categoryOrder.map(category => (
          <div key={category} className={styles.profileCategory}>
            <h3>{categoryDisplayNames[category]}</h3>
            <p className={styles.categoryLabel}>{categoryLabels[category]}</p>
            {userProfile[category].map((item, index) => (
              <div key={index} className={styles.profileItem}>
                {(editingItem?.category === category && editingItem.index === index) || item === '' ? (
                  <input
                    value={editingItem?.value ?? item}
                    onChange={(e) => handleProfileItemChange(e.target.value)}
                    onBlur={handleProfileItemSave}
                    autoFocus
                  />
                ) : (
                  <>
                    <span>{item}</span>
                    <button className={styles.smallButton} onClick={() => handleProfileItemEdit(category, index, item)}>Edit</button>
                    <button className={styles.smallButton} onClick={() => handleProfileItemRemove(category, index)}>X</button>
                  </>
                )}
              </div>
            ))}
            <button className={styles.smallButton} onClick={() => handleProfileItemAdd(category)}>Add Entry</button>
          </div>
        ))}
        <button onClick={handleSaveUserProfile} className={styles.saveProfileButton}>Save User Profile</button>
      </div>
    </ProfileWrapper>
  );
};

export default UserProfile;