import React, { ReactNode } from 'react';

interface PlainWrapperProps {
  children: ReactNode;
}

const PlainWrapper: React.FC<PlainWrapperProps> = ({ children }) => {
  const wrapperStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(to bottom, var(--gradient-white) 0%, var(--gradient-gold) 20%, var(--gradient-orange) 23%, var(--gradient-cream) 30%, var(--gradient-cream) 100%)',
  };

  return (
    <div style={wrapperStyle}>
      {children}
    </div>
  );
};

export default PlainWrapper;