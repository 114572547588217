// src/Documents.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import LoadingScreen from './TempScreens/LoadingScreen';
import ErrorMessageScreen from './TempScreens/ErrorMessageScreen';
import SubpageWrapper from './PageComponents/SubpageWrapper';
import styles from './Documents.module.css';

interface File {
  filename: string;
  url: string;
  last_modified: string;
}

interface DocumentsProps {
  userId: string;
}

const GET_USER_FILES = gql`
  query GetUserFiles($userId: String!) {
    getUserUploadedFiles(user_id: $userId) {
      files {
        filename
        url
        last_modified
      }
      status_code
    }
  }
`;

const Documents: React.FC<DocumentsProps> = ({ userId }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [searchTerm, setSearchTerm] = useState('');

  const { loading, error, data } = useQuery(GET_USER_FILES, {
    variables: { userId },
  });

  useEffect(() => {
    if (data && data.getUserUploadedFiles.status_code === 200) {
      setFiles(data.getUserUploadedFiles.files);
    }
  }, [data]);

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
  };

  const parseDate = (dateString: string): Date => {
    const [datePart, timePart] = dateString.split(', ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute] = timePart.split(':').map(Number);
    return new Date(year, month - 1, day, hour, minute);
  };

  const sortedAndFilteredFiles = useMemo(() => {
    return [...files]
      .sort((a, b) => {
        const dateA = parseDate(a.last_modified);
        const dateB = parseDate(b.last_modified);
        return sortOrder === 'asc' 
          ? dateA.getTime() - dateB.getTime() 
          : dateB.getTime() - dateA.getTime();
      })
      .filter(file =>
        file.filename.toLowerCase().includes(searchTerm.toLowerCase())
      );
  }, [files, sortOrder, searchTerm]);

  if (loading) return <LoadingScreen/>;
  if (error) return <ErrorMessageScreen/>;
  if (data && data.getUserUploadedFiles.status_code !== 200) return <ErrorMessageScreen/>;

  return (
    <SubpageWrapper pageTitle="Documents">
      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search filenames"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Filename</th>
            <th onClick={toggleSortOrder} style={{ cursor: 'pointer' }}>
              Date last modified {sortOrder === 'asc' ? '▲' : '▼'}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedAndFilteredFiles.map((file, index) => (
            <tr key={index}>
              <td>
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  {file.filename}
                </a>
              </td>
              <td>{file.last_modified} UTC</td>
            </tr>
          ))}
        </tbody>
      </table>
    </SubpageWrapper>
  );
};

export default Documents;