import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useQuery, useMutation, gql } from '@apollo/client';
import LoadingScreen from './TempScreens/LoadingScreen';
import ErrorMessageScreen from './TempScreens/ErrorMessageScreen';
import styles from './Home.module.css';
import ProfileWrapper from './PageComponents/ProfileWrapper';
import ActionItems from './PageComponents/ActionItems';
import CreditScoreBox from './PageComponents/CreditScoreBox';

const GET_USER_DATA = gql`
  query MyQuery($userId: String!, $refreshToken: String!) {
    getNubiUser(user_id: $userId, refresh_token: $refreshToken) {
      user_data {
        first_name
        last_name
        business_id
        credit_score_data {
          credit_score {
            credit_score
            timestamp
          }
        }
        action_items {
          title
          description
        }
      }
      status_code
      error_message
    }
  }
`;

const UPDATE_ACTION_ITEMS = gql`
  mutation UpdateActionItems($userId: String!, $actionItems: [ActionItemInput!]) {
    updateActionItems(user_id: $userId, action_items: $actionItems) {
      status_code
      error_message
    }
  }
`;

interface HomeProps {
  onBusinessIdLoaded: (businessId: string) => void;
  simulateReload: () => void;
}

interface ActionItem {
  title: string;
  description: string;
}

const Home: React.FC<HomeProps> = ({ onBusinessIdLoaded, simulateReload }) => {
  const { userId, getSession } = useAuth();
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<any>(null);
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [isAddingItem, setIsAddingItem] = useState(false);

  useEffect(() => {
    const fetchSessionToken = async () => {
      try {
        const session = await getSession();
        const token = session.getRefreshToken().getToken();
        setSessionToken(token);
      } catch (error) {
        console.error('Error retrieving session token:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSessionToken();
  }, [getSession]);
  

  const { loading: queryLoading, error: queryError, data } = useQuery(GET_USER_DATA, {
    variables: { userId, refreshToken: sessionToken || '' },
    skip: !sessionToken,
    onCompleted: (data) => {
      // console.log("USER ID: ", userId)
      // console.log("REFRESH TOKEN - completed: ", sessionToken)
      // console.log("QUERY COMPLETED!!! - DATA: ", data)
      if (data && data.getNubiUser && data.getNubiUser.user_data) {
        setUserData(data.getNubiUser.user_data);
        setActionItems(data.getNubiUser.user_data.action_items || []);
        if (data.getNubiUser.user_data.business_id) {
          onBusinessIdLoaded(data.getNubiUser.user_data.business_id);
        }
      }
    },
  });

  const [updateActionItems] = useMutation(UPDATE_ACTION_ITEMS);

  const handleSaveItems = async () => {
    if (!sessionToken) {
      console.error('No session token available');
      return;
    }
    try {
      const response = await updateActionItems({
        variables: {
          userId,
          actionItems: actionItems.map(({ title, description }) => ({ title, description })),
        },
      });
      if (response.data.updateActionItems.status_code === 200) {
        alert('Action items saved successfully');
      } else {
        console.error('Error saving action items:', response.data.updateActionItems.error_message);
      }
    } catch (error) {
      console.error('Error saving action items:', error);
    }
  };

  if (isLoading || (sessionToken && queryLoading)) return <LoadingScreen />;
  if (!sessionToken) return <ErrorMessageScreen />;
  if (queryError) {
    console.log("QUERY ERROR: ", queryError);
    // console.log("QUERY ERROR REFRESH TOKEN: ", sessionToken)
    return <ErrorMessageScreen />;
  }

  const welcomeMessage = `Welcome, ${userData?.first_name} ${userData?.last_name}`;
  const subtitle = `Business ID: ${userData?.business_id}`;

  return (
    <ProfileWrapper pageTitle={welcomeMessage} subtitle={subtitle} photoLink={null}>
      <div className={styles.mainContent}>
        <div className={styles.contentBox}>
          <ActionItems 
            items={actionItems} 
            onItemsChange={setActionItems}
            isAddingItem={isAddingItem}
            setIsAddingItem={setIsAddingItem}
          />
          <div className={styles.mainButtons}>
            <button className={styles.mainButton} onClick={() => setIsAddingItem(true)}>
              Add Action Item
            </button>
            <button className={styles.mainButton} onClick={handleSaveItems}>
              Save Action Items
            </button>
          </div>
        </div>
      </div>
      <CreditScoreBox 
        userId={userId}
        refreshToken={sessionToken}
        initialCreditScore={userData?.credit_score_data?.credit_score?.credit_score || 0}
        initialTimestamp={userData?.credit_score_data?.credit_score?.timestamp || ''}
      />
    </ProfileWrapper>
  );
};

export default Home;