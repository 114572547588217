import React, { createContext, useState, useContext } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { userPool } from './DDB_Client'; // Assuming we've exported userPool from DDB_Client

interface AuthContextType {
  userId: string;
  setUserId: (userId: string) => void;
  refreshToken: string;
  setRefreshToken: (refreshToken: string) => void;
  isAuth: boolean;
  setIsAuth: (isAuth: boolean) => void;
  businessId: string;
  setBusinessId: (businessId: string) => void;
  error: string | null;
  setError: (error: string | null) => void;
  linkBusiness: boolean;
  setLinkBusiness: (linkBusiness: boolean) => void;
  login: (username?: string, password?: string) => Promise<void>;
  logout: () => void;
  startingLogin: boolean;
  signifyStartingLogin: (isLoggedOut: boolean) => void;
  onLandingPage: boolean;
  signifyOnLandingPage: (onLandingPage: boolean) => void;
  getSession: () => Promise<CognitoUserSession>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userId, setUserId] = useState<string>('');
  const [refreshToken, setRefreshToken] = useState<string>('');
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [businessId, setBusinessId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [linkBusiness, setLinkBusiness] = useState<boolean>(false);
  const [startingLogin, signifyStartingLogin] = useState<boolean>(false);
  const [onLandingPage, signifyOnLandingPage] = useState<boolean>(false);

  const login = async (username?: string, password?: string) => {
    signifyStartingLogin(true);
    if (username && password) {
      // Perform login with credentials
      return new Promise<void>((resolve, reject) => {
        const user = new CognitoUser({ Username: username, Pool: userPool });
        const authDetails = new AuthenticationDetails({ Username: username, Password: password });

        user.authenticateUser(authDetails, {
          onSuccess: (session) => {
            setUserId(username);
            setRefreshToken(session.getRefreshToken().getToken());
            setIsAuth(true);
            resolve();
          },
          onFailure: (err) => {
            setError(err.message);
            reject(err);
          },
        });
      });
    } else {
      // Check current session
      try {
        const session = await getSession();
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
          setUserId(currentUser.getUsername());  // Set userId to the current user's username
        }
        setRefreshToken(session.getRefreshToken().getToken());
        setIsAuth(true);
      } catch (err) {
        throw err;
      }
    }
  };

  const logout = () => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    setUserId('');
    setRefreshToken('');
    setIsAuth(false);
    setBusinessId('');
    setError(null);
  };

  const getSession = (): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession((err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            reject(err);
          } else if (session) {
            resolve(session);
          } else if (!onLandingPage) {
            reject(new Error('No active session'));
          }
        });
      } else {
        reject(new Error('No current user'));
      }
    });
  };

  return (
    <AuthContext.Provider value={{
      userId,
      setUserId,
      refreshToken,
      setRefreshToken,
      isAuth,
      setIsAuth,
      businessId,
      setBusinessId,
      error,
      setError,
      linkBusiness,
      setLinkBusiness,
      login,
      logout,
      startingLogin,
      signifyStartingLogin,
      onLandingPage,
      signifyOnLandingPage,
      getSession,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};