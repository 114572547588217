import React, { useRef } from 'react';
import { DeepChat } from 'deep-chat-react';
import { Client, createClient } from 'graphql-ws';
// import styles from './Chat.module.css';

const url = process.env.REACT_APP_LLM_BACKEND_URL;

const GET_STREAMED_RESPONSE = `
  subscription getStreamedResponse($conversationId: String!, $query: String, $files: [Upload!]) {
    getStreamedResponse(conversationId: $conversationId, query: $query, files: $files) {
      chatbotResponse
      translateTo
      doc {
        htmlContent
        documentType
        links {
          format
          url
        }
      }
      actionItems {
        title
        description
      }
    }
  }
`;

const UPLOAD_FILES = `
  mutation UploadFiles($conversationId: String!, $files: [Upload!]!) {
    uploadFiles(conversationId: $conversationId, files: $files) {
      statusCode
      errorMessage
    }
  }
`

interface ChatbotResponse {
  chatbotResponse: string;
  translateTo: string | null;
  doc: {
    htmlContent: string;
    documentType: string;
    links: { format: string; url: string }[];
  } | null;
  actionItems: { title: string; description: string }[];
}

interface SubscriptionData {
  getStreamedResponse: ChatbotResponse;
}

interface FileUploadResponse {
  statusCode: number;
  errorMessage: string | null;
}

interface ChatComponentProps {
  conversationId: string;
  onMessage: (response: ChatbotResponse) => void;
  history: { role: string; text: string }[];
}

const ChatComponent: React.FC<ChatComponentProps> = React.memo(({ conversationId, onMessage, history }) => {
  const chatRef = useRef<any>(null);
  const clientRef = useRef<Client | null>(null);

  if (!clientRef.current) {
    clientRef.current = createClient({
      url: url || '',
      webSocketImpl: WebSocket,
    });
  }

  return (
    <DeepChat
      mixedFiles={true}
      ref={chatRef}
      style={{
        width: '100%',
        height: '100%',
        maxWidth: '100%'
      }}
      messageStyles={{
        "default": {
          "shared": {
            "bubble": {
              "maxWidth": "100%", "backgroundColor": "unset", "marginTop": "10px", "marginBottom": "10px"}},
          "user": {
            "bubble": {
              "marginLeft": "0px", "color": "black"}},
          "ai": {
            "outerContainer": {
              "backgroundColor": "#fff2e6", "borderTop": "1px solid rgba(0,0,0,.1)", "borderBottom": "1px solid rgba(0,0,0,.1)"
            }
          }
        }
      }}
      initialMessages={history}
      stream={true}
      request={{
        handler: async (body: any, signals: any) => {

          if (!url) {
            throw new Error('LLM Backend URL is missing. Check the environment variables.');
          }

          let variables: any = {
            conversationId: conversationId
          }

          if (body instanceof FormData) {

            let fileList = body.getAll('files');

            let message: any = body.get('message1');
            let parsed: any = JSON.parse(message);
            
            if (parsed !== null) {
              variables.query = parsed.text;
            }

            let file_mutation_input: any = {
              conversationId: conversationId
            }

            const formData = new FormData();

            file_mutation_input.files = fileList;

            formData.append('operations', JSON.stringify({
              query: UPLOAD_FILES,
              variables: file_mutation_input
            }));

            const map: { [key: string]: string[] } = {};
            fileList.forEach((file, index) => {
              map[index.toString()] = [`variables.files.${index}`];
            });

            formData.append('map', JSON.stringify(map));

            fileList.forEach((file, index) => {
              formData.append(index.toString(), file);
            });

            const file_upload_response = await fetch(url, {
              method: 'POST',
              body: formData,
            });

            const file_upload_result = await file_upload_response.json();
            const fileUploadResponse: FileUploadResponse = file_upload_result.data.uploadFiles;

            if (fileUploadResponse.statusCode !== 200) {
              alert("File upload failed.")
            }

          } else {
            variables.files = null;
            variables.query = body.messages[0].text.toString();
          }

          try {

            await new Promise((resolve, reject) => {
              clientRef.current!.subscribe<SubscriptionData>(
                {
                  query: GET_STREAMED_RESPONSE,
                  variables: variables,
                },
                {
                  next: (result) => {
                    // console.log("RESULT:", result)
                    if (result.data && 'getStreamedResponse' in result.data) {
                      const chatbotResponse = result.data.getStreamedResponse;
                      if (chatbotResponse.doc || chatbotResponse.actionItems.length > 0) {
                        onMessage(chatbotResponse);
                      }
                      if (chatbotResponse.chatbotResponse !== "[DONE]") {
                        signals.onResponse({ text: chatbotResponse.chatbotResponse });
                      }
                    }
                  },
                  error: (error) => {
                    console.error('Error in chat subscription:', error);
                    signals.onResponse({ error: 'An error occurred while processing your request.' });
                    reject(error);
                  },
                  complete: () => {
                    signals.onClose();
                    resolve(null);
                  },
                }
              );
            });
          } catch (e) {
            console.error('Error in chat request:', e);
            signals.onResponse({ error: 'An error occurred while processing your request.' });
          }
        }
      }}
    />
  );
});

export default ChatComponent;