import React from 'react';
import { motion } from 'framer-motion';
import logoImage from '../images/nubi-text-only.png';
import logoArcImage from '../images/nubi-arc.png';
import './LoadingScreen.css';  // We'll create this CSS file

const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-screen">
      <div className="logo-container">
        <img src={logoImage} alt="Logo" className="logo" />
        <motion.img
          src={logoArcImage}
          alt="Logo Arc"
          className="logo-arc"
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
        />
      </div>
      <p className="loading-text">Loading...</p>
    </div>
  );
};

export default LoadingScreen;