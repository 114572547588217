import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './SidebarHeader.module.css';
import { Menu, X } from 'lucide-react';

interface SidebarHeaderProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  isMobile: boolean;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = React.memo(({ isOpen, toggleSidebar, isMobile }) => {
  const location = useLocation();

  const getLinkClassName = (path: string) => {
    return `${styles.navLink} ${location.pathname === path ? styles.activeLink : ''}`;
  };

  return (
    <div className={`${styles.sidebar} ${isOpen ? styles.open : ''} ${isMobile ? styles.mobile : ''}`}>
      {isMobile && (
        <button className={styles.toggleButton} onClick={toggleSidebar}>
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      )}
      <div className={styles.logoContainer}>
        <img src="/nubi-logo.png" alt="Logo" className={styles.logo} />
      </div>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <li className={styles.navItem}><Link to="/home" className={getLinkClassName('/home')}>Home</Link></li>
          <li className={styles.navItem}>
            <Link to="/chat" className={getLinkClassName('/chat')}>Chat</Link>
            <ul className={styles.submenu}>
              <li><Link to="/incognito_chat" className={styles.submenuLink}>Incognito</Link></li>
            </ul>
          </li>
          <li className={styles.navItem}><Link to="/documents" className={getLinkClassName('/documents')}>Documents</Link></li>
          <li className={styles.navItem}><Link to="/networking" className={getLinkClassName('/networking')}>Networking</Link></li>
          <li className={styles.navItem}>
            <Link to="/user_profile" className={getLinkClassName('/user_profile')}>Profile</Link>
            <ul className={styles.submenu}>
              <li><Link to="/business_profile" className={styles.submenuLink}>Business Profile</Link></li>
            </ul>
          </li>
          <li className={styles.navItem}><Link to="/logout" className={getLinkClassName('/logout')}>Log Out</Link></li>
          {/* <li className={styles.navItem}><Link to="/settings" className={getLinkClassName('/settings')}>Settings</Link></li> */}
        </ul>
      </nav>
    </div>
  );
});

export default SidebarHeader;