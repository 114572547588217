import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { userPool } from '../DDB_Client';

import "./SignInPage.css"
import PlainWrapper from '../PageComponents/PlainWrapper';

interface SignInPageProps {
  onSignInSuccess: (userId: string, refreshToken: string) => void;
  onError: (message: string) => void;
}

const SignInPage: React.FC<SignInPageProps> = ({ onSignInSuccess, onError }) => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const authenticationDetails = new AuthenticationDetails({
      Username: userId,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: userId,
      Pool: userPool
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session: CognitoUserSession) => {
        setLoading(false);
        // console.log("Signin success!")
        const refreshToken = session.getRefreshToken().getToken();
        // console.log("Refresh token: ", refreshToken)
        // console.log("Received user ID: ", userId)
        onSignInSuccess(userId, refreshToken);
        navigate('/home');
      },
      onFailure: (err) => {
        setLoading(false);
        if (err.code === 'UserNotConfirmedException') {
          navigate('/verification', { state: { userId: userId } });
        } else if (err.code === 'NotAuthorizedException') {
          alert("Incorrect username or password.");
        } else {
          onError(err.message || 'An error occurred during sign in');
        }
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        setLoading(false);
        alert("Please change your password");
        // You might want to navigate to a change password page here
      }
    });
  };

  const handleCreateAccount = () => {
    navigate('/choose-account-type');
  };

  const handleForgotPassword = () => {
    // Add forgot password functionality here
    alert("Sorry, this hasn't been added yet.")
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <PlainWrapper>
      <div className="container sign-in-page">
        <h1>Sign In</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={userId}
            onChange={(e) => setUserId(e.target.value.toLowerCase())}
            placeholder="User ID"
            required
          />
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="password-toggle-button"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          <button type="submit" disabled={loading} className="sign-in-button">
            {loading ? 'Signing In...' : 'Sign In'}
          </button>
          <div className="text-buttons-container">
            <button type="button" onClick={handleCreateAccount} className="text-button">
              Create Account
            </button>
            <button type="button" onClick={handleForgotPassword} className="text-button">
              Forgot password?
            </button>
          </div>
        </form>
      </div>
    </PlainWrapper>
  );
};

export default SignInPage;