import React, { useState, useEffect, useCallback } from 'react';
import SidebarHeader from './SidebarHeader';
import styles from './SidebarLayout.module.css';

interface SidebarLayoutProps {
  children: React.ReactNode;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const handleResize = useCallback(() => {
    const mobile = window.innerWidth < 768;
    setIsMobile(mobile);
    setIsOpen(!mobile);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const toggleSidebar = useCallback(() => setIsOpen(prev => !prev), []);

  return (
    <div className={styles.layout}>
      <SidebarHeader isOpen={isOpen} toggleSidebar={toggleSidebar} isMobile={isMobile} />
      <main className={`${styles.mainContent} ${isOpen ? styles.sidebarOpen : ''}`}>
        {children}
      </main>
    </div>
  );
};

export default SidebarLayout;