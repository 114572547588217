import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';

import "./Verification.css"
import PlainWrapper from '../PageComponents/PlainWrapper';

const VERIFY_USER_MUTATION = gql`
  mutation VerifyNubiUser($userId: String!, $verificationCode: String!) {
    verifyNubiUser(user_id: $userId, verification_code: $verificationCode) {
      error_message
    }
  }
`;

const RESEND_CODE_MUTATION = gql`
  mutation ResendConfirmationCode($userId: String!) {
    resendConfirmationCode(user_id: $userId) {
      status_code
      error_message
    }
  }
`;

const Verification: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;

  const [verifyUser, { loading: verifyLoading }] = useMutation(VERIFY_USER_MUTATION, {
    onCompleted: (data) => {
      if (!data.verifyNubiUser.error_message) {
        navigate('/signin');
      } else {
        alert(data.verifyNubiUser.error_message);
      }
    },
    onError: (error) => {
      alert('An error occurred during verification');
    },
  });

  const [resendCode, { loading: resendLoading }] = useMutation(RESEND_CODE_MUTATION, {
    onCompleted: (data) => {
      if (data.resendConfirmationCode.status_code === 200) {
        alert('Verification code has been resent to your email.');
      } else {
        alert(data.resendConfirmationCode.error_message || 'An error occurred while resending the code.');
      }
    },
    onError: (error) => {
      alert('An error occurred while resending the verification code');
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (verificationCode.length !== 6) {
      alert('Please enter a 6-digit verification code');
      return;
    }
    verifyUser({ variables: { userId, verificationCode } });
  };

  const handleResendCode = (e: React.MouseEvent) => {
    e.preventDefault();
    resendCode({ variables: { userId } });
  };

  if (!userId) {
    navigate('/signin');
    return null;
  }

  return (
    <PlainWrapper>
      <div className="container verification-page">
        <h1>Verify Your Account</h1>
        <p>Please enter the 6-digit verification code sent to your email.</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="6-digit code"
            maxLength={6}
            pattern="\d{6}"
            required
          />
          <button type="submit" disabled={verifyLoading}>
            {verifyLoading ? 'Verifying...' : 'Verify'}
          </button>
          <p className="resend-text">
            <a href="#" onClick={handleResendCode} style={{ fontSize: '0.8em', color: '#007bff', textDecoration: 'none' }}>
              {resendLoading ? 'Resending...' : 'Resend verification code'}
            </a>
          </p>
        </form>
      </div>
    </PlainWrapper>
  );
};

export default Verification;