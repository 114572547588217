import React from 'react';
import SidebarLayout from './SidebarLayout';
import styles from './SubpageWrapper.module.css';

interface SubpageWrapperProps {
  pageTitle?: string;
  children: React.ReactNode;
  maxWidth?: string;
  showHeader?: boolean;
}

const SubpageWrapper: React.FC<SubpageWrapperProps> = React.memo(({ pageTitle, children, maxWidth, showHeader = true }) => {
  const contentStyle = maxWidth ? { maxWidth } : {};

  // DON'T EVER SAVE NON-STATIC CONTENT AS A CONST
  // const Content = () => (
  //   <div className={styles.pageContainer}>
  //     <div className={styles.mainContent} style={contentStyle}>
  //       {pageTitle && <h1>{pageTitle}</h1>}
  //       {children}
  //     </div>
  //   </div>
  // );

  return showHeader ? (
    <SidebarLayout>
      <div className={styles.pageContainer}>
        <div className={styles.mainContent} style={contentStyle}>
          {pageTitle && <h1>{pageTitle}</h1>}
          {children}
        </div>
      </div>
    </SidebarLayout>
  ) : (
    <div className={styles.pageContainer}>
      <div className={styles.mainContent} style={contentStyle}>
        {pageTitle && <h1>{pageTitle}</h1>}
        {children}
      </div>
    </div>
  );
});

export default SubpageWrapper;