import React from 'react';
import './ChooseAccountTypePage.css';
import PlainWrapper from '../PageComponents/PlainWrapper';

interface ChooseAccountTypePageProps {
    onChoice: (linkBusiness: boolean) => void;
}

const ChooseAccountTypePage: React.FC<ChooseAccountTypePageProps> = ({ onChoice }) => {
    return (
      <PlainWrapper>
        <div className="container choose-account-type-page">
          <h1>Choose Account Type</h1>
          <p>Do you want to link your account with an existing business ID?</p>
          <div className="button-group">
            <button onClick={() => onChoice(true)}>Yes, link to a business</button>
            <button onClick={() => onChoice(false)}>No, my business is not yet on Nubi</button>
          </div>
        </div>
      </PlainWrapper>
    );
  };

export default ChooseAccountTypePage;