import React from 'react';
import './ErrorMessageScreen.css';

interface ErrorMessageScreenProps {
  title?: string;
  message?: string;
  onRetry?: () => void;
}

const ErrorMessageScreen: React.FC<ErrorMessageScreenProps> = ({
  title = "Oops! Something went wrong",
  message = "We're sorry, but an error occurred while processing your request.",
  onRetry
}) => {
  return (
    <div className="error-screen">
      <div className="error-container">
        <div className="error-content">
          <svg
            className="error-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <h2 className="error-title">{title}</h2>
          <p className="error-message">{message}</p>
        </div>
        {onRetry && (
          <div className="error-action">
            <button onClick={onRetry} className="retry-button">
              Try Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorMessageScreen;