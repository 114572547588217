import React, { useState } from 'react';
import { ApolloProvider } from "@apollo/client";
import { useNavigate } from 'react-router-dom';
import llm_backend_client from './LLM-Backend-Client';
import Chat from './Chat';
import PlainWrapper from './PageComponents/PlainWrapper';
import styles from './LandingPage.module.css';

const defaultHistory = [
  { role: 'user', text: 'What is Nubi Finance?' },
  { role: 'ai', text: 'Nubi Finance is an innovative platform designed to empower entrepreneurs with AI-powered business tools. Our advanced chatbot offers multilingual support, business mentorship, and personalized advice drawn from reputable educational institutions. We help you create business plans, generate invoices, calculate credit scores, and provide actionable insights to grow your business. With features like document support, research capabilities, and a user profile that learns from your interactions, Nubi Finance is your AI partner in entrepreneurial success.' }
];

const tabContent = [
  {
    title: 'Business Advice and Research',
    description: 'Get personalized business mentorship and advice from our AI. It can also search the internet for up-to-date information.',
    samplePrompts: [
      'How involved should founders be in marketing and sales?',
      'What are some effective marketing strategies for startups?',
      'Find me investors in Cape Town'
    ]
  },
  {
    title: 'Business Plan',
    description: 'Generate comprehensive business plans tailored to your needs, with the ability to modify and iterate based on feedback.',
    samplePrompts: [
      'Create a business plan for my tech startup.',
      'Make the customers section more specific.',
      'In the marketing section, mention that...'
    ]
  },
  {
    title: 'Invoice',
    description: 'Easily generate and manage invoices in various formats, ensuring professional and accurate billing for your business.',
    samplePrompts: [
      'Generate an invoice for 50 hours of consulting services, at $20 per hour.',
      'Add a 10% discount.',
      'Make the headings in Comic Sans.'
    ]
  },
  {
    title: 'Credit Score',
    description: 'Calculate and understand your business credit score using our AI-powered analysis of your financial data.',
    samplePrompts: [
      'Calculate my business credit score.',
      'How can I improve my business credit score?',
      'Generate a report detailing the financials behind the credit score calculation.'
    ]
  }
];

const LandingPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  return (
    <PlainWrapper>
      <button className={styles.signInButton} onClick={() => navigate('/signin')}>
        Sign In
      </button>
      <div className={styles.landingPage}>
        <div className={styles.heroSection}>
          <h1 className={styles.title}>Welcome to Nubi Finance</h1>
          <p className={styles.subtitle}>Your AI-powered business partner</p>
        </div>
        
        <div className={styles.chatContainer}>
          <ApolloProvider client={llm_backend_client}>
            <Chat 
              userId="demo_user" 
              refreshToken="demo_token" 
              incognito={true} 
              includeSidebar={false} 
              history={defaultHistory}
            />
          </ApolloProvider>
        </div>

        <div className={styles.featuresContainer}>
          <h2 className={styles.featuresTitle}>Explore Our Features</h2>
          <div className={styles.tabsContainer}>
            {tabContent.map((tab, index) => (
              <button
                key={tab.title}
                className={`${styles.tabButton} ${activeTab === index ? styles.activeTab : ''}`}
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className={styles.tabContent}>
            <h3>{tabContent[activeTab].title}</h3>
            <p>{tabContent[activeTab].description}</p>
            <h4>Sample Prompts:</h4>
            <ul>
              {tabContent[activeTab].samplePrompts.map((prompt, index) => (
                <li key={index}>{prompt}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </PlainWrapper>
  );
};

export default LandingPage;