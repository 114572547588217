import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { createAuthLink, AuthOptions } from 'aws-appsync-auth-link';

const url = process.env.REACT_APP_APPSYNC_API_URL;
const region = 'us-east-1';
const apiKey = process.env.REACT_APP_APPSYNC_API_KEY;

if (!url || !region || !apiKey) {
  throw new Error('AWS AppSync configuration is incomplete. Check your .env file.');
}
// Define the auth configuration
const auth: AuthOptions = {
  type: 'API_KEY',
  apiKey: apiKey,
};

const httpLink = createHttpLink({ uri: url });

const authLink = createAuthLink({ url, region, auth });

const link = authLink.concat(httpLink);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;