import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import CountryDropdown from '../PageComponents/CountryDropdown';
import PasswordComponent from '../PageComponents/Password';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import './CreateAccountPage.css';
import PlainWrapper from '../PageComponents/PlainWrapper';

const CREATE_USER_MUTATION = gql`
  mutation CreateNubiUser($input: CreateNubiUserInput!) {
    createNubiUser(input: $input) {
      user_id
      business_id
      status_code
      error_message
    }
  }
`;

interface CreateAccountPageProps {
  onError: (message: string) => void;
  linkBusiness: boolean;
}

const CreateAccountPage: React.FC<CreateAccountPageProps> = ({ onError, linkBusiness }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/signin');
  };

  const [formData, setFormData] = useState({
    user_id: '',
    password: '',
    email: '',
    first_name: '',
    last_name: '',
    country: '',
    city: '',
    locale: 'en-US',
    business_id: '',
    business_password: '',
    is_funder: false,
    is_mentor: false,
    is_partner: false
  });

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [userIdError, setUserIdError] = useState('');

  const [createUser] = useMutation(CREATE_USER_MUTATION);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'user_id') {
      setFormData(prev => ({ ...prev, [name]: value }));
      validateUserId(value);
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const validateUserId = (userId: string) => {
    if (userId.length < 3 || userId.length > 24) {
      setUserIdError('User ID must be between 3 and 24 characters long.');
    } else if (!/^[a-z0-9-_]+$/.test(userId)) {
      setUserIdError('User ID can only contain lowercase letters (a-z), numbers (0-9), hyphens (-), and underscores (_).');
    } else {
      setUserIdError('');
    }
  };

  const handlePasswordChange = (password: string) => {
    setFormData(prev => ({ ...prev, password }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (userIdError) {
      alert('Please correct the User ID before submitting.');
      return;
    }
    
    // Log the entire formData object
    // console.log('Form data before submission:', formData);
    
    // Create the input object
    const input = {
      ...formData,
      business_id: linkBusiness ? formData.business_id : undefined,
      business_password: linkBusiness ? formData.business_password : undefined,
    };
    
    // Log the input object
    console.log('Mutation input:', input);
    
    try {
      const { data } = await createUser({
        variables: { input },
      });
      console.log('Mutation response:', data);
      if (data.createNubiUser.status_code === 200) {
        navigate('/verification', { state: { userId: data.createNubiUser.user_id } });
      } else {
        alert(data.createNubiUser.error_message || 'Account creation failed');
      }
    } catch (error) {
      alert('An error occurred during account creation. Please try again later.');
    }
  };

  return (
    <PlainWrapper>
      <div className="container create-account-page">
        <h1>Create Account</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              name="user_id"
              value={formData.user_id}
              onChange={handleChange}
              placeholder="User ID (lowercase only)"
              required
            />
            {userIdError && <p className="error-text">{userIdError}</p>}
          </div>
          <PasswordComponent
            password={formData.password}
            onPasswordChange={handlePasswordChange}
            onValidityChange={setIsPasswordValid}
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <input
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <input
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <CountryDropdown value={formData.country} onChange={handleChange} />
          <input
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
            required
          />
          <select name="locale" value={formData.locale} onChange={handleChange} required>
            <option value="en-US">English (US)</option>
            <option value="en-ZA">English (South Africa)</option>
            <option value="fr-CA">French (Canada)</option>
          </select>
          {linkBusiness ? (
            <>
              <h3>Link to Nubi Business Account</h3>
              <input
                name="business_id"
                type="number"
                value={formData.business_id}
                onChange={handleChange}
                placeholder="Business ID"
                required
              />
              <input
                type="password"
                name="business_password"
                value={formData.business_password}
                onChange={handleChange}
                placeholder="Business Verification Code"
                required
              />
            </>
          ) : (
            <>
              <h3>Business Type</h3>
              <p>How would you like your business to be matched with others?</p>
              <div className="checkbox-group">
                <Tooltip title="Based on your preferences, you will be matched with other businesses you may choose to fund.">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.is_funder}
                        onChange={handleCheckboxChange}
                        name="is_funder"
                      />
                    }
                    label="Funder"
                  />
                </Tooltip>
                <Tooltip title="You will be matched with other users who may be interested in learning from you.">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.is_mentor}
                        onChange={handleCheckboxChange}
                        name="is_mentor"
                      />
                    }
                    label="Mentor"
                  />
                </Tooltip>
                <Tooltip title="You will periodically be matched with nearby, similar businesses for potential partnership opportunities.">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.is_partner}
                        onChange={handleCheckboxChange}
                        name="is_partner"
                      />
                    }
                    label="Partner"
                  />
                </Tooltip>
              </div>
            </>
          )}
          <button type="submit" disabled={!isPasswordValid || !!userIdError}>Create Account</button>
        </form>
        <button onClick={handleBack}className="back-button">Back to Sign In</button>
      </div>
    </PlainWrapper>
  );
};

export default CreateAccountPage;