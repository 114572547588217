import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

// Define your API URLs
const HTTP_URL = process.env.REACT_APP_LLM_BACKEND_URL;
const WS_URL = process.env.REACT_APP_LLM_BACKEND_URL;

if (!HTTP_URL) {
    throw new Error('LLM Backend URL is missing. Check the environment variables.');
}

if (!WS_URL) {
    throw new Error('LLM Backend URL is missing. Check the environment variables.');
}

// Create an HTTP link for queries and mutations
const httpLink = new HttpLink({
  uri: HTTP_URL
});

// Create a WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(createClient({
  url: WS_URL,
  connectionParams: {
    reconnect: true,
    // You can pass authentication headers here if needed
    // authToken: localStorage.getItem('auth_token')
  }
}));

// Use the split function to direct traffic between the links
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

// Create the Apollo Client instance
const llm_backend_client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache()
});

export default llm_backend_client;