import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

const url = process.env.REACT_APP_APPSYNC_API_URL;
const region = 'us-east-1';
const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const clientId = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;

if (!url || !region || !userPoolId || !clientId) {
  throw new Error('AWS AppSync or Cognito configuration is incomplete. Check your .env file.');
}

const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS' as const,
  jwtToken: async (): Promise<string> => {
    const session = await getCurrentSession();
    return session.getIdToken().getJwtToken();
  },
};

export const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
});

// Function to get the current session
const getCurrentSession = (): Promise<CognitoUserSession> => {

  if (window.location.pathname === '/landingpage') {
    // Create a CognitoUserSession with blank tokens
    const blankIdToken = new CognitoIdToken({ IdToken: '' });
    const blankAccessToken = new CognitoAccessToken({ AccessToken: '' });
    const blankRefreshToken = new CognitoRefreshToken({ RefreshToken: '' });
    
    const blankSession = new CognitoUserSession({
      IdToken: blankIdToken,
      AccessToken: blankAccessToken,
      RefreshToken: blankRefreshToken
    });

    // Return a resolved promise with the blank session
    return Promise.resolve(blankSession);
  }

  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          reject(err);
        } else if (session) {
          resolve(session);
        } else {
          reject(new Error('No active session'));
        }
      });
    } else {
      reject(new Error('No active user'));
    }
  });
};

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

export default client;

// Export authentication functions
export const signIn = (username: string, password: string): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => resolve(session),
      onFailure: (err) => reject(err),
    });
  });
};

export const signOut = (): void => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export const isAuthenticated = (): Promise<boolean> => {
  return getCurrentSession()
    .then(() => true)
    .catch(() => false);
};