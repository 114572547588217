import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import styles from './Chat.module.css';
import LoadingScreen from './TempScreens/LoadingScreen';
import ErrorMessageScreen from './TempScreens/ErrorMessageScreen';
import SubpageWrapper from './PageComponents/SubpageWrapper';
import ChatComponent from './ChatComponent';
import ArtifactsContainer from './PageComponents/ArtifactsContainer';
import ddb_client from './DDB_Client';

const GET_CONVERSATION_ID = gql`
  mutation getConversationId($userId: String!, $refreshToken: String!, $incognito: Boolean) {
    getConversationId(userId: $userId, refreshToken: $refreshToken, type: "business", translate: true, incognito: $incognito)
  }
`;

interface ConversationInitializerProps {
  userId: string;
  refreshToken: string;
  incognito: boolean;
  includeSidebar?: boolean;
  history?: { role: string; text: string }[];
}

interface DocumentVersion {
  htmlContent: string;
  links: { format: string; url: string }[];
}

interface ActionItem {
  id: string;
  title: string;
  description: string;
  saved: boolean;
}

interface ChatbotResponse {
  chatbotResponse: string;
  translateTo: string | null;
  doc: {
    htmlContent: string;
    documentType: string;
    links: { format: string; url: string }[];
  } | null;
  actionItems: { title: string; description: string }[];
}

const tabs = ["Chat", "Business Plan", "Invoice", "Credit Score Report", "Action Items"];

const defaultHistory = [
  { role: 'ai', text: 'How can I help?' },
];

const Chat: React.FC<ConversationInitializerProps> = ({ userId, refreshToken, incognito, includeSidebar = true, history = defaultHistory }) => {
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [getConversationId] = useMutation(GET_CONVERSATION_ID);
  const [error, setError] = useState<boolean>(false);
  const [documents, setDocuments] = useState<{
    [key: string]: DocumentVersion[];
  }>({
    "Business Plan": [],
    "Invoice": [],
    "Credit Score Report": [],
  });
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [activeTab, setActiveTab] = useState<string>("Chat");

  useEffect(() => {
    const initializeConversation = async () => {
      try {
        const { data: conversationData } = await getConversationId({
          variables: { userId, refreshToken, incognito },
        });
        const newConversationId = conversationData.getConversationId;
        setConversationId(newConversationId);
      } catch (error) {
        console.error('Error initializing conversation:', error);
        setError(true);
      }
    };

    if (userId && refreshToken) {
      initializeConversation();
    }
  }, [userId, refreshToken, incognito, getConversationId]);

  const handleChatbotResponse = useCallback((response: ChatbotResponse) => {
    if (response.doc) {
      const { documentType, htmlContent, links } = response.doc;
      setDocuments(prevDocuments => {
        const updatedDocuments = { ...prevDocuments };
        if (!updatedDocuments[documentType]) {
          updatedDocuments[documentType] = [];
        }
        updatedDocuments[documentType] = [
          ...updatedDocuments[documentType],
          { htmlContent, links }
        ];
        return updatedDocuments;
      });
    }
    if (response.actionItems) {
      setActionItems(prevItems => [
        ...prevItems,
        ...response.actionItems.map(item => ({
          id: Math.random().toString(36).substr(2, 9),
          ...item,
          saved: false
        }))
      ]);
    }
  }, []);

  const handleUpdateActionItems = useCallback((updatedItems: ActionItem[]) => {
    setActionItems(updatedItems);
  }, []);

  const memoizedChatComponent = useMemo(() => {
    if (conversationId) {
      return (
        <ChatComponent 
          key={conversationId}
          conversationId={conversationId}
          onMessage={handleChatbotResponse}
          history={history}
        />
      );
    }
    return null;
  }, [conversationId, handleChatbotResponse]);

  const tabsRef = useRef<HTMLDivElement>(null);

  const scrollToTab = useCallback((tabName: string) => {
    const tabElement = tabsRef.current?.querySelector(`button:nth-child(${tabs.indexOf(tabName) + 1})`);
    if (tabElement) {
      tabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, []);

  const handleTabClick = useCallback((tab: string) => {
    setActiveTab(tab);
    scrollToTab(tab);
  }, [scrollToTab]);

  if (error) {
    return <ErrorMessageScreen />;
  }

  if (!conversationId) {
    return <LoadingScreen />;
  }

  const renderContent = () => (
    <div className={`${styles.contentContainer} ${!includeSidebar ? styles.noSidebar : ''}`}>
      <div className={styles.tabsContainer} ref={tabsRef}>
        {tabs.map(tab => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ''}`}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className={styles.tabContentWrapper}>
        <div className={styles.tabContent}>
          <div className={styles.chatWrapper} style={{ display: activeTab === "Chat" ? 'flex' : 'none' }}>
            <div className={styles.chatComponent}>
              {memoizedChatComponent}
            </div>
          </div>
          {activeTab !== "Chat" && (
            <ApolloProvider client={ddb_client}>
              <ArtifactsContainer
                userId={userId}
                documents={documents}
                actionItems={actionItems}
                onUpdateActionItems={handleUpdateActionItems}
                activeTab={activeTab}
              />
            </ApolloProvider>
          )}
        </div>
      </div>
    </div>
  );

  return includeSidebar ? (
    <SubpageWrapper maxWidth='96vw'>
      {renderContent()}
    </SubpageWrapper>
  ) : (
    renderContent()
  );
};

export default Chat;