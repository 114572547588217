// src/AccessCodePage.tsx
import React, { useState } from 'react';
import axios from 'axios';
import PlainWrapper from './PageComponents/PlainWrapper';
import styles from './AccessCodePage.module.css';

interface AccessCodePageProps {
  onVerificationSuccess: () => void;
}

const AccessCodePage: React.FC<AccessCodePageProps> = ({ onVerificationSuccess }) => {
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      // Replace with your actual Lambda function URL
      const response = await axios.post('https://l7ugvavov2zpznkeskzplboara0ouuhk.lambda-url.us-east-1.on.aws/', { accessCode });
      console.log("RESPONSE: ", response)
      
      if (response.status) {
        onVerificationSuccess();
      } else {
        setError('Invalid access code. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <PlainWrapper>
      <div className={styles['access-code-form']}>
        <h1 className={styles['access-code-title']}>Enter Access Code</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            placeholder="Enter access code"
            required
            className={styles['access-code-input']}
          />
          <button type="submit" className={styles['access-code-button']}>Submit</button>
        </form>
        {error && <p className={styles['access-code-error']}>{error}</p>}
      </div>
    </PlainWrapper>
  );
};

export default AccessCodePage;