import React from 'react';
import SidebarLayout from './SidebarLayout';
import styles from './SubpageWrapper.module.css';

interface ProfileWrapperProps {
  pageTitle: string;
  subtitle: string;
  photoLink: string | null;
  children: React.ReactNode;
}

const ProfileWrapper: React.FC<ProfileWrapperProps> = ({ pageTitle, subtitle, photoLink, children }) => {
  return (
    <SidebarLayout>
      <div className={styles.pageContainer}>
        <div className={styles.mainContent}>
          <div className={styles.profileHeader}>
            {photoLink && (
              <img 
                src={photoLink} 
                alt="Profile" 
                className={styles.profileImage}
              />
            )}
            <div className={styles.titleContainer}>
              <h1>{pageTitle}</h1>
              <h4>{subtitle}</h4>
            </div>
          </div>
          {children}
        </div>
      </div>
    </SidebarLayout>
  );
};

export default ProfileWrapper;