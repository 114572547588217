import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import SubpageWrapper from './PageComponents/SubpageWrapper';
import styles from './Networking.module.css';

const GET_USER_DATA = gql`
  query GetUserData($userId: String!, $refreshToken: String!) {
    getNubiUser(user_id: $userId, refresh_token: $refreshToken) {
      user_data {
        business_matches {
          complementary_partners {
            similarity
            bio
            business_name
            logo_link
            phone_number
            primary_email
          }
          potential_funders {
            bio
            business_name
            logo_link
            phone_number
            primary_email
            similarity
          }
          potential_investments {
            bio
            business_name
            logo_link
            phone_number
            primary_email
            similarity
          }
          similar_partners {
            bio
            business_name
            logo_link
            phone_number
            primary_email
            similarity
          }
        }
        user_matches {
          potential_mentees {
            bio
            email
            first_name
            last_name
            photo_link
            similarity
          }
          potential_mentors {
            bio
            email
            first_name
            last_name
            photo_link
            similarity
          }
        }
      }
    }
  }
`;

const UPDATE_MATCHES = gql`
  mutation UpdateMatches($businessId: String!, $userId: String!) {
    updateMatches(business_id: $businessId, user_id: $userId) {
      business_matches {
        complementary_partners {
          bio
          business_name
          logo_link
          primary_email
          similarity
          phone_number
        }
        potential_funders {
          bio
          business_name
          phone_number
          logo_link
          similarity
          primary_email
        }
        potential_investments {
          bio
          business_name
          logo_link
          phone_number
          primary_email
          similarity
        }
        similar_partners {
          bio
          business_name
          logo_link
          phone_number
          primary_email
          similarity
        }
      }
      status_code
      error_message
      user_matches {
        potential_mentees {
          bio
          email
          first_name
          last_name
          photo_link
          similarity
        }
        potential_mentors {
          bio
          email
          first_name
          last_name
          photo_link
          similarity
        }
      }
    }
  }
`;

interface NetworkingProps {
  userId: string;
  refreshToken: string;
  businessId: string;
  simulateReload: () => void;
}

const Networking: React.FC<NetworkingProps> = ({ userId, refreshToken, businessId, simulateReload }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const { loading, error, data, refetch } = useQuery(GET_USER_DATA, {
    variables: { userId, refreshToken },
  });

  const [updateMatches, { loading: updateLoading }] = useMutation(UPDATE_MATCHES);

  useEffect(() => {
    refetch();
  }, [refetch, simulateReload]);

  const categories = [
    "Potential Partners",
    "Potential Funders",
    "Potential Investments",
    "Potential Mentors",
    "Potential Mentees"
  ];

  const getMatchQualityColor = (similarity: number) => {
    if (similarity < 0.5) return 'red';
    if (similarity < 0.7) return '#ffd700';
    return 'green';
  };

  const renderMatchBox = (match: any, isBusiness: boolean) => {
    const name = isBusiness ? (match.business_name || "No Business Name") : `${match.first_name} ${match.last_name}`;
    const imageLink = isBusiness ? match.logo_link : match.photo_link;
    const email = isBusiness ? match.primary_email : match.email;

    return (
      <div key={name} className={styles.matchBox}>
        <img src={imageLink} alt={name} className={styles.matchImage} />
        <div className={styles.matchInfo}>
          <h3>{name}</h3>
          <p>{match.bio}</p>
          <p>Phone: {match.phone_number}</p>
          <p>Email: {email}</p>
          <div 
            className={styles.matchQuality} 
            style={{ backgroundColor: getMatchQualityColor(match.similarity) }}
          >
            Match Quality: {Math.round(match.similarity * 100)}%
          </div>
        </div>
      </div>
    );
  };

  const renderMatches = (matches: any[] | null, isBusiness: boolean) => {
    if (!matches || matches.length === 0) {
      return <p>No matches found in this category.</p>;
    }
    return matches.map((match: any) => renderMatchBox(match, isBusiness));
  };

  const renderTabContent = () => {
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const { business_matches, user_matches } = data?.getNubiUser?.user_data || {};

    if (!business_matches && !user_matches) {
      return <p>No data available</p>;
    }

    switch (activeTab) {
      case 0: // Potential Partners
        return business_matches ? (
          <>
            {renderMatches(business_matches.similar_partners, true)}
            {renderMatches(business_matches.complementary_partners, true)}
          </>
        ) : <p>No potential partners data available</p>;
      case 1: // Potential Funders
        return business_matches ? renderMatches(business_matches.potential_funders, true) : <p>No potential funders data available</p>;
      case 2: // Potential Investments
        return business_matches ? renderMatches(business_matches.potential_investments, true) : <p>No potential investments data available</p>;
      case 3: // Potential Mentors
        return user_matches ? renderMatches(user_matches.potential_mentors, false) : <p>No potential mentors data available</p>;
      case 4: // Potential Mentees
        return user_matches ? renderMatches(user_matches.potential_mentees, false) : <p>No potential mentees data available</p>;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (tabContainerRef.current) {
      const activeTabElement = tabContainerRef.current.children[activeTab] as HTMLElement;
      if (activeTabElement) {
        const containerWidth = tabContainerRef.current.offsetWidth;
        const tabWidth = activeTabElement.offsetWidth;
        const tabLeft = activeTabElement.offsetLeft;
        const scrollLeft = tabLeft - (containerWidth - tabWidth) / 2;
        
        tabContainerRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth'
        });
      }
    }
  }, [activeTab]);

  const handleUpdateMatches = async () => {
    try {
      const result = await updateMatches({
        variables: { businessId, userId }
      });
      if (result.data?.updateMatches?.status_code === 200) {
        // refetch(); // Refetch the data to update the UI
        simulateReload();
      } else {
        console.error('Error updating matches:', result.data?.updateMatches?.error_message);
      }
    } catch (error) {
      console.error('Error updating matches:', error);
    }
  };

  return (
    <SubpageWrapper pageTitle="Networking">
      <div className={styles.tabContainer} ref={tabContainerRef}>
        {categories.map((category, index) => (
          <button
            key={category}
            className={`${styles.tabButton} ${index === activeTab ? styles.active : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tabContent} key={activeTab}>
          {renderTabContent()}
        </div>
        <button 
          className={styles.updateButton} 
          onClick={handleUpdateMatches}
          disabled={updateLoading}
        >
          {updateLoading ? 'Updating...' : 'Update matches'}
        </button>
      </div>
    </SubpageWrapper>
  );
};

export default Networking;