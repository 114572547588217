import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import ProfileWrapper from './PageComponents/ProfileWrapper';
import CountryDropdown from './PageComponents/CountryDropdown';
import LoadingScreen from './TempScreens/LoadingScreen';
import ErrorMessageScreen from './TempScreens/ErrorMessageScreen';
import styles from './BusinessProfile.module.css';

const GET_BUSINESS_DATA = gql`
  query GetNubiBusiness($businessId: String!) {
    getNubiBusiness(business_id: $businessId) {
      business_name
      business_password
      business_profile {
        for_procurement
        services_rendered
        to_invest
      }
      city
      country
      is_funder
      is_partner
      logo_link
      phone
      primary_email
      members
    }
  }
`;

const UPDATE_BUSINESS_DATA = gql`
  mutation UpdateBusinessData($input: UpdateBusinessDataInput!) {
    updateBusinessData(input: $input) {
      status_code
      error_message
    }
  }
`;

interface BusinessProfileProps {
  businessId: string;
  simulateReload: () => void;
}

const BusinessProfile: React.FC<BusinessProfileProps> = ({ businessId, simulateReload }) => {
  const { loading, error, data, refetch } = useQuery(GET_BUSINESS_DATA, {
    variables: { businessId },
  });

  const [updateBusinessData] = useMutation(UPDATE_BUSINESS_DATA);

  useEffect(() => {
    refetch();
  }, [refetch, simulateReload]);

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    business_name: '',
    city: '',
    country: '',
    phone: '',
    primary_email: '',
    logo_link: null as File | null,
  });

  const [businessProfile, setBusinessProfile] = useState({
    services_rendered: [] as string[],
    for_procurement: [] as string[],
    to_invest: [] as string[],
  });

  const [members, setMembers] = useState<string[]>([]);
  const [businessPassword, setBusinessPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [editingItem, setEditingItem] = useState<{category: string, index: number, value: string} | null>(null);

  useEffect(() => {
    if (data?.getNubiBusiness) {
      const { business_name, city, country, phone, primary_email, business_profile, members, business_password } = data.getNubiBusiness;
      setFormData({ business_name, city, country, phone, primary_email, logo_link: null });
      setBusinessProfile({
        services_rendered: business_profile.services_rendered || [],
        for_procurement: business_profile.for_procurement || [],
        to_invest: business_profile.to_invest || [],
      });
      setMembers(members);
      setBusinessPassword(business_password);
    }
  }, [data]);

  if (loading) return <LoadingScreen/>;
  if (error) return <ErrorMessageScreen/>;

  const businessData = data.getNubiBusiness;

  const handleMemberRemove = (index: number) => {
    setMembers(prev => prev.filter((_, i) => i !== index));
  };

  const handleMemberAdd = () => {
    setMembers(prev => [...prev, '']);
  };

  const handleMemberChange = (index: number, value: string) => {
    setMembers(prev => prev.map((item, i) => i === index ? value : item));
  };

  const handleSaveBusinessInfo = async () => {
    try {
      const { data } = await updateBusinessData({
        variables: {
          input: {
            business_id: businessId,
            members,
          },
        },
      });
      if (data.updateBusinessData.status_code !== 200) {
        alert(data.updateBusinessData.error_message);
      } else {
        alert('Business information updated successfully');
      }
    } catch (error) {
      alert('An error occurred while updating business information.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, logo_link: e.target.files[0] });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let logoLinkJson = null;
      if (formData.logo_link) {
        const base64 = await convertFileToBase64(formData.logo_link);
        logoLinkJson = JSON.stringify({
          filename: formData.logo_link.name,
          type: formData.logo_link.type,
          base64: base64
        });
      }

      const { data } = await updateBusinessData({
        variables: {
          input: {
            ...formData,
            logo_link: logoLinkJson,
            business_id: businessId,
          },
        },
      });
      if (data.updateBusinessData.status_code !== 200) {
        alert(data.updateBusinessData.error_message);
      } else {
        setIsEditing(false);
      }
    } catch (error) {
      alert("An error occurred while updating business data.")
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const handleProfileItemRemove = (category: string, index: number) => {
    setBusinessProfile(prev => ({
      ...prev,
      [category]: prev[category as keyof typeof prev].filter((_, i) => i !== index)
    }));
  };

  const handleProfileItemEdit = (category: string, index: number, value: string) => {
    setEditingItem({ category, index, value });
  };

  const handleProfileItemSave = () => {
    if (editingItem) {
      const { category, index, value } = editingItem;
      setBusinessProfile(prev => ({
        ...prev,
        [category]: prev[category as keyof typeof prev].map((item, i) => i === index ? value : item)
      }));
      setEditingItem(null);
    }
  };

  const handleProfileItemChange = (value: string) => {
    if (editingItem) {
      setEditingItem({ ...editingItem, value });
    }
  };

  const handleProfileItemAdd = (category: string) => {
    setBusinessProfile(prev => ({
      ...prev,
      [category]: [...prev[category as keyof typeof prev], '']
    }));
    setEditingItem({ category, index: businessProfile[category as keyof typeof businessProfile].length, value: '' });
  };

  const handleSaveBusinessProfile = async () => {
    try {
      const { data } = await updateBusinessData({
        variables: {
          input: {
            business_id: businessId,
            business_profile: {
              services_rendered: businessProfile.services_rendered,
              for_procurement: businessProfile.for_procurement,
              to_invest: businessProfile.to_invest
            }
          },
        },
      });
      if (data.updateBusinessData.status_code !== 200) {
        alert(data.updateBusinessData.error_message);
      } else {
        simulateReload();
        alert('Business profile updated successfully');
      }
    } catch (error) {
      // console.log("ERROR: ", error)
      alert('An error occurred while updating business profile.');
    }
  };

  const categoryOrder = ['services_rendered', 'for_procurement', 'to_invest'] as const;
  
  const categoryLabels = {
    services_rendered: "List the services your business provides. This will be used to match you with similar, nearby businesses.",
    for_procurement: "List the goods or services your business needs. If your needs match the services rendered by any nearby businesses, you will be matched.",
    to_invest: "If you are a funder, list your investment criteria or areas of interest for investment."
  };

  const categoryDisplayNames = {
    services_rendered: "Services Rendered",
    for_procurement: "Business Needs",
    to_invest: "Investment Criteria"
  };

  return (
    <ProfileWrapper
      pageTitle={businessData.business_name || "No Business Name"}
      subtitle={`${businessId} | ${businessData.city}, ${businessData.country}`}
      photoLink={businessData.logo_link}
    >
      <button onClick={() => setIsEditing(true)} className={styles.editButton}>
        Update Business Data
      </button>

      {isEditing && (
        <div className={styles.editPanel}>
          <form onSubmit={handleSubmit}>
            <input
              name="business_name"
              value={formData.business_name}
              onChange={handleInputChange}
              placeholder="Business Name"
            />
            <input
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
            />
            <CountryDropdown
              value={formData.country}
              onChange={handleInputChange}
            />
            <input
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone"
            />
            <input
              name="primary_email"
              value={formData.primary_email}
              onChange={handleInputChange}
              placeholder="Primary Email"
            />
            <input
              type="file"
              onChange={handleFileUpload}
              accept="image/*"
            />
            <button type="submit">Save Changes</button>
            <button type="button" onClick={() => setIsEditing(false)}>Cancel</button>
          </form>
        </div>
      )}

      <div className={styles.businessProfileBox}>
        <h2>Business Profile</h2>
        {categoryOrder.map(category => (
          <div key={category} className={styles.profileCategory}>
            <h3>{categoryDisplayNames[category]}</h3>
            <p className={styles.categoryLabel}>{categoryLabels[category]}</p>
            {businessProfile[category].map((item, index) => (
              <div key={index} className={styles.profileItem}>
                {(editingItem?.category === category && editingItem.index === index) || item === '' ? (
                  <input
                    value={editingItem?.value ?? item}
                    onChange={(e) => handleProfileItemChange(e.target.value)}
                    onBlur={handleProfileItemSave}
                    autoFocus
                  />
                ) : (
                  <>
                    <span>{item}</span>
                    <button className={styles.smallButton} onClick={() => handleProfileItemEdit(category, index, item)}>Edit</button>
                    <button className={styles.smallButton} onClick={() => handleProfileItemRemove(category, index)}>X</button>
                  </>
                )}
              </div>
            ))}
            <button className={styles.smallButton} onClick={() => handleProfileItemAdd(category)}>Add Entry</button>
          </div>
        ))}
        <button onClick={handleSaveBusinessProfile} className={styles.saveProfileButton}>Save Business Profile</button>
      </div>
      <div className={styles.businessInfoBox}>
        <h2>Business Information</h2>
        <div className={styles.membersList}>
          <h3>Members</h3>
          {members.map((member, index) => (
            <div key={index} className={styles.memberItem}>
              <input
                value={member}
                onChange={(e) => handleMemberChange(index, e.target.value)}
              />
              <button className={styles.smallButton} onClick={() => handleMemberRemove(index)}>Remove</button>
            </div>
          ))}
          <button className={styles.smallButton} onClick={handleMemberAdd}>Add Member</button>
        </div>
        <div className={styles.passwordSection}>
          <h3>Business Password</h3>
          <p>New Nubi users can use this password to link their accounts to your business</p>
          <div className={styles.passwordDisplay}>
            <span>{showPassword ? businessPassword : '•'.repeat(businessPassword.length)}</span>
            <button
              className={styles.togglePasswordButton}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
        </div>
        <button onClick={handleSaveBusinessInfo} className={styles.saveProfileButton}>Save Business Information</button>
      </div>
    </ProfileWrapper>
  );
};

export default BusinessProfile;
